/*eslint-disable*/
import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import { navigate } from "@reach/router"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Checkbox from "@material-ui/core/Checkbox"

import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"

// @material-ui/icons
import Code from "@material-ui/icons/Code"
import Computer from "@material-ui/icons/Computer"
import Check from "@material-ui/icons/Check"
import AllInclusiveIcon from "@material-ui/icons/AllInclusive"
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
import Lock from "@material-ui/icons/Lock"

// core components
import GridContainer from "../components/material-kit-pro/components/Grid/GridContainer"
import GridItem from "../components/material-kit-pro/components/Grid/GridItem"
import Card from "../components/material-kit-pro/components/Card/Card"
import CardBody from "../components/material-kit-pro/components/Card/CardBody"

import InfoArea from "../components/material-kit-pro/components/InfoArea/InfoArea"
import CustomInput from "../components/material-kit-pro/components/CustomInput/CustomInput"

// own
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"
import SEO from "../components/seo"
import BackgroundImageDots from "../components/BackgroundImageDots"

import signupPageStyle from "../components/material-kit-pro/styles/signupPageStyle"

const useStyles = makeStyles(signupPageStyle)

export default function SignUpPage({ location, ...rest }) {
  const [checkedTerms, setChecked] = React.useState(false)
  const handleToggle = value => {
    setChecked(!checkedTerms)
  }
  const [isSubmitted, setSubmitted] = React.useState(false)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()

  const [userData, setUserData] = useState({
    identifier: "",
    password: "",
    name: "",
  })
  const [createdUser, setCreatedUser] = useState(null)

  const [errorMessage, setErrorMessage] = useState("")

  const image = useStaticQuery(graphql`
    query TwitterSignupImage {
      twitter: file(relativePath: { eq: "seo/SEOImageFormat.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            quality: 95
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
  `)

  const api = process.env.GATSBY_STRAPI_API

  const sendConfirmationEmail = email => {
    axios
      .post(api + "/auth/send-email-confirmation", {
        email,
      })
      .catch(e => {
        if (
          e.response.data.message &&
          e.response.data.message[0] &&
          e.response.data.message[0].messages &&
          e.response.data.message[0].messages[0]
        ) {
          setErrorMessage(e.response.data.message[0].messages[0].message)
        } else {
          setErrorMessage(e.message)
        }
      })
  }

  const onChange = e => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = e => {
    setSubmitted(true)
    e.preventDefault()

    const websiteApp = process.env.GATSBY_ADMIN_URL

    if (!checkedTerms) {
      // Validation, user side
      setErrorMessage(
        "You need to accept our Terms of Service and Privacy Policy."
      )
      setSubmitted(false)
    } else {
      axios
        .post(api + "/auth/local/register", {
          ...userData,
          // Same data
          email: userData.email,
          username: userData.email,
          identifier: userData.email,
        })
        .then(response => {
          setCreatedUser({ email: userData.email })

          // If confirmation email is not on, user can login directly
          // localStorage.setItem("token", response.data.jwt)
          // navigate(app)
        })
        .catch(e => {
          if (
            e.response.data.message &&
            e.response.data.message[0] &&
            e.response.data.message[0].messages &&
            e.response.data.message[0].messages[0]
          ) {
            setErrorMessage(e.response.data.message[0].messages[0].message)
          } else {
            setErrorMessage(e.message)
          }
        })
        .finally(() => {
          setSubmitted(false)
        })
    }
  }

  return !createdUser ? (
    <div>
      <Header compact />
      <BackgroundImageDots className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h1 className={classes.cardTitle}>
                  Register Now & Easily Translate i18n Locales
                </h1>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={5} md={5}>
                      <InfoArea
                        className={classes.infoArea}
                        title="Web Translator"
                        description="Simply copy/paste your HTML, JSON, Markdown, PHP Arrays, Properties and YAML structure to translate"
                        icon={Computer}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Automate i18n Translations"
                        description="Use the Simpleen CLI Tool to integrate translations into your development workflow"
                        icon={AllInclusiveIcon}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Supports Many i18n Libraries"
                        description={
                          <span>
                            Format.JS, i18next, ngx-translate, Transloco and{" "}
                            <Link
                              to="/translate-i18n-locale"
                              className={classes.link}
                            >
                              more
                            </Link>
                          </span>
                        }
                        icon={Code}
                        iconColor="primary"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      <form className={classes.form} onSubmit={onSubmit}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            ariaLabel: "Name",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Name",
                            name: "name",
                            onChange: onChange,
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            ariaLabel: "Email",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email",
                            name: "email",
                            onChange: onChange,
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            ariaLabel: "Password",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Lock className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            type: "password",
                            placeholder: "Password",
                            autoComplete: "off",
                            name: "password",
                            onChange: onChange,
                          }}
                        />
                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={
                            <Checkbox
                              // tabIndex={-1}
                              onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              checked={checkedTerms}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <Link
                                tabIndex={-1}
                                to="/terms-service"
                                className={classes.link}
                              >
                                Terms of Service
                              </Link>{" "}
                              and{" "}
                              <Link
                                tabIndex={-1}
                                to="/privacy-policy"
                                className={classes.link}
                              >
                                Privacy Policy
                              </Link>
                              .
                            </span>
                          }
                        />

                        <div className={classes.textCenter}>
                          <FormHelperText error={!!errorMessage}>
                            {errorMessage}
                          </FormHelperText>

                          <Button
                            type="submit"
                            round
                            color="primary"
                            disabled={isSubmitted}
                            variant="contained"
                          >
                            Get started
                          </Button>

                          <p>
                            Already have an account?{" "}
                            <Link className={classes.link} to="/login">
                              Log In
                            </Link>
                            .
                          </p>
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </BackgroundImageDots>
      <Footer withFlex />
      <SEO
        general={{
          title: "Sign Up to Simpleen | Start Translating i18n Locales",
          description:
            "Create an account and directly translate software, games, (web)apps & documentations. ",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </div>
  ) : (
    /* 2. step - Verify email address */
    <div>
      <Header compact />
      <BackgroundImageDots className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h1 className={classes.cardTitle}>
                  Verify your email to finish signing up for Simpleen
                </h1>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <p style={{ textAlign: "center" }}>
                        Thank you for choosing Simpleen. <br />
                        We've sent an email to{" "}
                        <strong>{createdUser.email}</strong> so you can confirm
                        your account.
                      </p>
                    </GridItem>

                    <GridItem xs={12}>
                      <p style={{ textAlign: "center" }}>
                        You didn't get the email? <br />
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginTop: "0.5rem" }}
                          onClick={() => {
                            sendConfirmationEmail(createdUser.email)
                          }}
                        >
                          Send Email again
                        </Button>
                      </p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </BackgroundImageDots>
      <Footer withFlex />
      <SEO
        general={{
          title: "Sign Up to Simpleen | Start Translating i18n Locales",
          description:
            "Create an account and directly translate software, games, (web)apps & documentations. ",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </div>
  )
}
